<template>
  <div>
      <overlay v-if="hotelsOverlay"/>
      <v-progress-linear
      indeterminate
      color="secondary"
      height="10"
      top
      :active="loaded"
      ></v-progress-linear>
      <v-snackbar v-model="snackbar" :color="color" top :timeout="60000">
          {{ text }} <router-link to="/hotels" style="color: blue; margin-left: 5px;"> Search again</router-link>
          <v-btn
          dark
          icon
          @click="snackbar = false"
          >
              <v-icon color="white">mdi-close</v-icon>
          </v-btn>
      </v-snackbar>
      <v-container v-if="noData">
          <p class="body-1 font-weight-bold primary--text text-center">Sorry, We couldn't get any result <router-link to="/hotels" style="color: blue; margin-left: 5px;"> Search again</router-link></p>
      </v-container>
      <v-container class="mt-5" v-if="data">
          <v-row class="px-3">
              <h3 class="font-weight-bold">{{hotel.HotelName}}</h3>
              <v-rating :length="hotel.HotelStars" color="warning" readonly small :value="Number(hotel.HotelStars)" full-icon="mdi-star"></v-rating>
              <v-spacer></v-spacer>
              <div v-if="hotel.tripAdivsorRating">
                  <v-avatar color="indigo" size="36">
                      <span class="white--text headline">{{hotel.tripAdivsorRating}}</span>
                  </v-avatar>
              </div>
          </v-row>
          <v-row class="px-3">
              <v-icon small class="mt-0" color="secondary">mdi-map-marker</v-icon>
              <p class="body-2 mb-0 mt-1">{{hotel.HotelAddress}},{{hotel.HotelCity}},{{hotel.HotelCountry}}</p>
              <v-btn text color="blue" small @click="openMap(hotel.map)">
                  Show on map
              </v-btn>
          </v-row>
          <v-row class="px-3">
            <v-icon small class="mr-1" color="secondary">mdi-phone</v-icon>{{hotel.HotelPhone}}
            <v-icon small class="mr-1 ml-1" color="secondary">mdi-fax</v-icon>{{hotel.HotelFax}}
          </v-row>
          <div class="mt-10">
            <Gallery :images="images" ></Gallery>
          </div>
          <h3 class="mt-10">Most PopularFcilities</h3>
          <v-slide-group multiple show-arrows>
              <v-slide-item
              v-for="n in hotel.HotelFacilities.length"
              :key="n"
              >
                  <v-chip
                  class="ma-2"
                  outlined
                  color="secondary"
                  >
                  {{hotel.HotelFacilities[n]}}
                  </v-chip>
              </v-slide-item>
          </v-slide-group>

          <div v-if="typeof allrooms === 'string'">
              <v-alert
              border="top"
              color="error lighten-2"
              dark
              >
              {{allrooms}}
              </v-alert>
          </div>
          <div v-else>
              <h3 class="mt-10">Available Rooms</h3>
              <v-row>
                <v-col cols="12" sm="6" lg="4" xl="3" v-for="(item, j) in allrooms" :key="'room'+j">
                  <v-card>
                    <v-carousel v-if="item.images.length" height="200" hide-delimiters show-arrows-on-hover cycle>
                      <v-carousel-item
                        eager
                        v-for="(image,i) in item.images"
                        :key="i"
                        :src="image"
                      ></v-carousel-item>
                    </v-carousel>
                    <v-card-title class="orange--text mb-3 word-break" style="word-break: break-word;">{{item.roomName}}</v-card-title>

                    <v-tabs v-if="item.roomRates.length > 1" center-active centered v-model="tabs[j]" background-color="grey lighten-4" slider-color="blueDark">
                      <v-tab v-for="rate in item.roomRates" :key="rate.ratePlanCode" class="font-weight-bold" active-class="blueDark--text">{{rate.rateSummary.totalFare | changeCurrency}}</v-tab>
                    </v-tabs>

                    <v-tabs-items v-model="tabs[j]">
                      <v-tab-item
                        v-for="(rate, index) in item.roomRates"
                        :key="rate.ratePlanCode"
                      >
                      <v-radio-group v-if="rate.beds.length > 1" v-model="rate.selectedBedGroup">
                        <v-card class="mx-3 mb-2" elevation="0" color="blue lighten-5" v-for="bed in rate.beds" :key="bed.bedGroupId">
                          <v-card-title class="body-2 font-weight-bold blueDark--text d-flex justify-space-between">
                            <div><v-icon left color="blue">mdi-bed</v-icon>{{bed.description}}</div>
                            <v-radio color="blue" :value="bed.bedGroupId"></v-radio>
                          </v-card-title>
                        </v-card>
                      </v-radio-group>

                      <v-card-subtitle :class="rate.refundable ? 'success--text' : 'error--text'">{{rate.refundable ? 'Fully Refundable' : 'Not Refundable'}}</v-card-subtitle>
                      <v-expansion-panels class="elevation-0" flat multiple :value="[0, 1]">
                        <v-expansion-panel v-if="rate.amenities">
                          <v-expansion-panel-header>
                            Aminities
                          </v-expansion-panel-header>
                          <v-expansion-panel-content>
                            <div v-html="rate.amenities.replaceAll('|', '<br>')"></div>
                          </v-expansion-panel-content>
                        </v-expansion-panel>
                        <v-expansion-panel v-if="rate.supplements.length">
                          <v-expansion-panel-header>
                            Supplements
                          </v-expansion-panel-header>
                          <v-expansion-panel-content>
                            <div v-for="(supp, i) in rate.supplements" :key="i">
                              <div class="d-flex justify-space-between">
                                <p class="mb-1  body-2 text-capitalize">{{supp.name.replaceAll('_', ' ')}}</p>
                                <p class="mb-1  body-2 text-capitalize">{{supp.currencyCode.replaceAll('_', ' ')}} {{supp.price}}</p>
                              </div>
                            </div>
                          </v-expansion-panel-content>
                        </v-expansion-panel>
                        <v-expansion-panel v-if="rate.cancelPolicies">
                          <v-expansion-panel-header>
                            Cancellation Policies
                          </v-expansion-panel-header>
                          <v-expansion-panel-content>
                            <div v-if="rate.cancelPolicies.firstCancellationDeadline">- Free cancellation until {{new Date(rate.cancelPolicies.firstCancellationDeadline).toGMTString()}}.</div>
                            <div v-if="rate.cancelPolicies.lastCancellationDeadLine">- The end time for the cancellation window is {{new Date(rate.cancelPolicies.lastCancellationDeadLine).toGMTString()}} at which time the booking will become fully non-refundable.</div>
                            <div v-if="rate.cancelPolicies.nonRefundableDates.length">
                              - Any booked nights during the non-refundable date ranges (
                              <div v-for="(item, idx) in rate.cancelPolicies.nonRefundableDates" :key="idx">
                                {{new Date(item.fromDate).toGMTString()}} - {{new Date(item.toDate).toGMTString()}} <span v-if="idx < rate.cancelPolicies.nonRefundableDates.length - 1"> and </span>
                              </div>
                              are completely non-refundable from time of booking.
                              <!-- - Nights booked that fall within the exception window
                              {{new Date(rate.cancelPolicies.nonRefundableStartDate).toGMTString()}} to {{new Date(rate.cancelPolicies.nonRefundableEndDate).toGMTString()}}
                              provide no refund on cancellation. -->
                            </div>
                            <div v-for="(policy, i) in rate.cancelPolicies.policies" :key="'policy-'+i">
                              <div class="body-2" v-if="policy.chargeType === 'Nights' && policy.cancellationCharge > 0">- Cancellations made after {{new Date(policy.fromDate).toGMTString()}} are subject to a {{policy.cancellationCharge}} night(s) penalty plus taxes and fees.</div>
                              <div class="body-2" v-if="policy.chargeType === 'Fixed' && policy.cancellationCharge > 0">- Cancellations made after {{new Date(policy.fromDate).toGMTString()}} will result in an {{policy.currency + ' ' + policy.cancellationCharge}} fee.</div>
                              <div class="body-2" v-if="policy.chargeType === 'Percentage' && policy.cancellationCharge > 0">- Cancellations made after {{new Date(policy.fromDate).toGMTString()}} re subject to a {{policy.cancellationCharge}}% of total price penalty.</div>
                            </div>
                            <div>- If you fail to check-in for this reservation, or if you cancel or change this reservation after check-in, you may incur penalty charges at the discretion of the property of up to 100% of the booking value.</div>
                          </v-expansion-panel-content>
                        </v-expansion-panel>
                      </v-expansion-panels>
                      <v-card-actions>
                        <div>
                          <div class="text-h6 pl-2">{{rate.rateSummary.totalFare | changeCurrency}}</div>
                          <v-btn color="blueDark" @click="priceDetails = {daysRate: rate.daysRate, summary: rate.rateSummary, supplements: rate.supplements, tab: {group: j, value: index}}; tabs[j] = index; priceDialog = true;" text small class="px-2" dark>
                            PRICE DETAILS
                            <v-icon small right>mdi-information</v-icon>
                          </v-btn>
                        </div>
                        <v-spacer></v-spacer>
                        <v-btn rounded color="orange" class="white--text px-5" elevation="0" @click="reserve(rate, item.roomIndex)">
                          <span v-if="$store.state.hotelSearch.guestsInfo.length > 1">Reserve {{$store.state.hotelSearch.guestsInfo.length}} rooms</span>
                          <span v-else>reserve room</span>
                        </v-btn>
                      </v-card-actions>
                      </v-tab-item>
                    </v-tabs-items>
                  </v-card>
                </v-col>
              </v-row>
          </div>

          <h3 class="mt-10">More Details</h3>
          <div v-html="hotel.HotelDescription"></div>
          <div v-html="hotel.nearByPlaces"></div>

          <v-dialog
          v-model="dialog"
          max-width="80%"
          >
              <gmap-map
              :center="center"
              :zoom="16"
              style="width:100%;  height: 500px;"
              zoomControl
              class="mt-1"
              >
                  <gmap-marker
                  :key="index"
                  v-for="(m, index) in markers"
                  :position="m.position"
                  @click="center=m.position"
                  :clickable="true"
                  :draggable="true"
                  ></gmap-marker>
              </gmap-map>
          </v-dialog>

          <!-- price details dialog -->
          <v-dialog
          v-model="priceDialog"
          max-width="500"
          >
            <v-card v-if="priceDetails">
              <v-card-title class="text-h5 grey lighten-3 blueDark--text">
                PRICE DETAILS
              </v-card-title>

              <v-card-text>
                <h4 class="orange--text my-2">Days Rate</h4>
                <v-expansion-panels class="elevation-1" flat>
                  <v-expansion-panel v-for="(rate, i) in priceDetails.daysRate" :key="'day-rate-' + i">
                    <v-expansion-panel-header>
                      <div class="d-flex justify-space-between body-2">
                        <div>{{new Date(rate.date).toDateString()}}</div>
                      </div>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <div v-if="rate.baseFare" class="d-flex justify-space-between body-2">
                        <div>Base Fare</div>
                        {{rate.baseFare | changeCurrency}}
                      </div>
                      <div v-if="rate.adjustment" class="d-flex justify-space-between body-2">
                        <div>Adjustment</div>
                        {{rate.adjustment | changeCurrency}}
                      </div>
                      <div v-if="rate.extra_person_fee" class="d-flex justify-space-between body-2">
                        <div>Extra person fee</div>
                        {{rate.extra_person_fee | changeCurrency}}
                      </div>
                      <div v-if="rate.recovery_charges_and_fees" class="d-flex justify-space-between body-2">
                        <div>Recovery charges and fees</div>
                        {{rate.recovery_charges_and_fees | changeCurrency}}
                      </div>
                      <div v-if="rate.property_fee" class="d-flex justify-space-between body-2">
                        <div>Property fee</div>
                        {{rate.property_fee | changeCurrency}}
                      </div>
                      <div v-if="rate.sales_tax" class="d-flex justify-space-between body-2">
                        <div>Sales tax</div>
                        {{rate.sales_tax | changeCurrency}}
                      </div>
                      <div v-if="rate.tax_and_service_fee" class="d-flex justify-space-between body-2">
                        <div>Tax and service fee</div>
                        {{rate.tax_and_service_fee | changeCurrency}}
                      </div>
                      <div v-if="rate.traveler_service_fee" class="d-flex justify-space-between body-2">
                        <div>Traveller service fee</div>
                        {{rate.traveler_service_fee | changeCurrency}}
                      </div>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>

                <h4 class="orange--text my-3">Rate Summary</h4>
                <div class="d-flex justify-space-between body-2 black--text">
                  <div>Base Fare</div>
                  {{priceDetails.summary.baseFare | changeCurrency}}
                </div>
                <div class="d-flex justify-space-between body-2 black--text">
                  <div>Affiliate booking charge</div>
                  {{priceDetails.summary.serviceFeesTotal | changeCurrency}}
                </div>
                <div class="d-flex justify-space-between body-2 black--text">
                  <div>
                    <v-tooltip bottom color="blue darken-4">
                      <template v-slot:activator="{ on, attrs }">
                        <span
                        v-bind="attrs"
                        v-on="on"
                        >Total taxes and service fees <v-icon color="blue darken-4" right small>mdi-information</v-icon></span>
                      </template>
                      <span class="caption">This charge includes estimated amounts the travel service provider (i.e. hotel, car rental company) pays for their taxes, <br> and/or taxes that we pay, to taxing authorities on your booking (including but not limited to sales, occupancy, and value added tax). <br /> This amount may also include any amounts charged to us for resort fees, cleaning fees, and other fees and/or a fee we, <br> the hotel supplier and/or the website you booked on, retain as part of the compensation for our and/or their services, <br> which varies based on factors such as location, the amount, and how you booked. For more details, please see the Terms and Conditions.</span>
                    </v-tooltip>
                  </div>
                  {{priceDetails.summary.tax | changeCurrency}}
                </div>
                <v-card elevation="0" color="grey lighten-3" class="pa-1 my-1">
                  <v-card-title class="px-0 body-2 font-weight-bold">Fees paid at property</v-card-title>
                  <div v-for="(fee, n) in priceDetails.supplements" :key="n" class="d-flex justify-space-between">
                    <p class="mb-1  body-2 text-capitalize">{{fee.name.replaceAll('_', ' ')}}</p>
                    <p class="mb-1  body-2 text-capitalize">{{fee.currencyCode.replaceAll('_', ' ')}} {{fee.price}}</p>
                  </div>
                  <span class="caption">*This price includes taxes and stay taxes.</span>
                  <span class="caption">*This conversion is an approximate price based on the current exchange rate and may change before you stay.</span>
                </v-card>
                <div class="d-flex justify-space-between body-1 black--text">
                  <div>Total Price</div>
                  {{priceDetails.summary.totalFare | changeCurrency}}
                </div>
              </v-card-text>
            </v-card>
          </v-dialog>
      </v-container>
  </div>
</template>

<script>
import { getHotel, headersNoAuth } from '../links'
import overlay from '../components/overlay'
import Gallery from 'vue-cover-gallery'

export default {
  title: '-Room reservation',
  components: {
    overlay,
    Gallery
  },
  data () {
    return {
      loaded: true,
      snackbar: false,
      room: 1,
      color: '',
      text: '',
      noData: false,
      data: false,
      hotel: {},
      hotelRooms: [],
      dialog: false,
      dialog1: false,
      markers: [],
      center: {
        lat: 0,
        lng: 0
      },
      current: 0,
      length: null,
      images: [],
      allrooms: [],
      availableRooms: [],
      selectedRooms: [],
      supplements: [],
      hotelsTimeOut: null,
      hotelsOverlay: false,
      now: null,
      prevTime: null,
      priceDialog: false,
      priceDetails: null,
      tabs: []
    }
  },
  watch: {
    priceDialog (newVal) {
      this.tabs[this.priceDetails.tab.group] = this.priceDetails.tab.value
    },
    data (newVal) {
      if (newVal) {
        setTimeout(() => {
          const element = document.getElementsByClassName('img-left')[0]
          element.classList.add('col-sm-12')
          element.classList.add('col-md-6')
        }, 100)
      }
    }
  },
  methods: {
    openMap (position) {
      this.dialog = !this.dialog
      this.center.lat = parseFloat(position.Latitude)
      this.center.lng = parseFloat(position.Longitude)
      this.markers.push({ position: this.center })
    },
    next () {
      this.current = this.current + 1 === this.length
        ? 0
        : this.current + 1
    },
    prev () {
      this.current = this.current - 1 < 0
        ? this.length - 1
        : this.current - 1
    },
    hideElement (el, n) {
      if (el === 'btn') document.getElementById(`btn-${n}`).style.display = 'none'
      else {
        document.getElementById(`card-${n}`).style.display = 'none'
        this.current = this.current + 1 === this.length ? 0 : this.current + 1
      }
    },
    formatDate (date) {
      const [year, month, day] = date.split('-')
      return `${day}/${month}/${year}`
    },
    roomImageError (i) {
      document.getElementById(`room-image-${i}`).style.display = 'none'
    },
    getAvailableRooms (index, compination) {
      compination = [...new Set(compination)]
      this.availableRooms = []
      this.selectedRooms = []
      const checkboxes = document.getElementsByClassName('check')
      // checkboxes.forEach(checkbox => {
      //   checkbox.checked = false
      // })
      for (let index = 0; index < checkboxes.length; index++) {
        checkboxes[index].checked = false
      }
      // this.availableRooms.push(this.allrooms[index])
      this.allrooms.forEach(room => {
        if (room.roomIndex === index) this.availableRooms.push(room)
        if (this.$store.state.hotelSearch.guestsInfo.length > 1 || this.$route.params.roomsNo > 1) {
          for (let i = 0; i < compination.length; i++) {
            if (room.roomIndex === compination[i] && room.roomIndex !== index) this.availableRooms.push(room)
          }
        }
      })
    },
    selectRoom (room, i) {
      if (this.selectedRooms.length < this.$store.state.hotelSearch.guestsInfo.length && document.getElementById(`checkbox-${i}`).checked) {
        this.selectedRooms.push(room)
        document.getElementById(`checkbox-${i}`).checked = true
      } else if (this.selectedRooms.length <= this.$store.state.hotelSearch.guestsInfo.length && !document.getElementById(`checkbox-${i}`).checked) {
        this.selectedRooms = this.selectedRooms.filter((val, index, arr) => {
          return val.roomIndex !== room.roomIndex
        })
        document.getElementById(`checkbox-${i}`).checked = false
      } else {
        document.getElementById(`checkbox-${i}`).checked = false
      }
    },
    reserve (room, roomIndex) {
      const params = {
        sessionId: this.$route.params.sessionId,
        hotelIndex: this.$route.params.hotelIndex,
        ratePlanCode: room.ratePlanCode,
        bedGroupId: room.selectedBedGroup,
        priceDetails: { daysRate: room.daysRate, rateSummary: room.rateSummary, supplements: room.supplements },
        rooms: [],
        roomIndex: roomIndex,
        hotel: this.hotel
      }
      for (let i = 0; i < this.$store.state.hotelSearch.guestsInfo.length; i++) {
        params.rooms.push(room)
      }
      clearTimeout(this.hotelsTimeOut)
      this.hotelsOverlay = false
      const spentTime = Date.now() - this.now
      const timer = this.prevTime - spentTime
      localStorage.setItem('hotelsTimer', timer)
      this.$router.push({ name: 'hotelBook', params: params })
    }
  },
  updated () {
    if (this.allrooms.length) {
      this.allrooms.forEach((room, i) => {
        this.tabs[i] = 0
        room.roomRates.forEach(rate => {
          if (!rate.selectedBedGroup) rate.selectedBedGroup = rate.beds[0].bedGroupId
        })
      })
    }
  },
  created () {
    window.scrollTo(0, 0)
    if (this.$route.params) {
      this.now = Date.now()
      this.hotelsTimeOut = setTimeout(() => {
        this.hotelsOverlay = true
      }, 20 * 60 * 1000)
      // this.prevTime = localStorage.getItem('hotelsTimer')
      const params = this.$route.params
      this.$http.get(getHotel(params.sessionId, params.hotelIndex, params.hotelCode), { headers: headersNoAuth() }).then(res => {
        if (res.body.status === 200) {
          this.loaded = false
          this.$store.dispatch('setHotelTimeOut', false)
          document.body.style.height = '100%'
          this.$store.dispatch('setHotelSearchTimeoutFunction', setTimeout(() => {
            this.$store.dispatch('setHotelTimeOut', true)
          }, 20 * 60 * 1000)
          )
          this.data = true
          this.hotel = res.body.data.Hotel
          this.length = res.body.data.Hotel.images.length
          res.body.data.Hotel.images.forEach(item => {
            this.images.push({
              title: '',
              description: '',
              href: item
            })
          })
          this.allrooms = res.body.data.HotelRooms
          if (typeof this.allrooms !== 'string') {
            this.allrooms.forEach((room, i) => {
              this.tabs[i] = 0
              room.roomRates.forEach(rate => {
                rate.selectedBedGroup = rate.beds[0].bedGroupId
              })
            })
            this.getAvailableRooms(this.hotelRooms[0].roomIndex, this.hotelRooms[0].roomCombination ? this.hotelRooms[0].roomCombination : [])
          }
        } else {
          this.loaded = false
          this.noData = true
        }
      }, () => {
        this.loaded = false
        this.noData = true
      })
    } else {
      this.$router.push({ name: 'hotels' })
    }
  },
  beforeDestroy () {
    clearTimeout(this.hotelsTimeOut)
    this.hotelsOverlay = false
  }
}
</script>

<style>
.check {
  width: 19px;
  height: 50px;
  position: absolute;
  top: 0;
  left: 1px;
}
.img-right, .img-left {
  border-color: white !important;
  cursor: pointer;
}
.img-left {
  height: 500px !important;
}
.img-right {
  height: 250px !important;
}
.inner-banner-outer {
  height: 500px !important;
  overflow: hidden !important;
}
.more-btn:hover {
  background-color: #2F80C2 !important;
}
</style>
